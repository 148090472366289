/* eslint-disable @typescript-eslint/no-explicit-any */
import { SortableContext, useSortable, arrayMove } from '@dnd-kit/sortable';
import { ComponentRef, forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { FavouriteForm } from '../../models/Form';
import { CSS } from '@dnd-kit/utilities';
import { DndContext, DragEndEvent, DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import DragHandleIcon from '../shared/icon/DragHandleIcon';
import TrashIcon from '../shared/icon/TrashIcon';
import { GroupBase } from 'react-select';
import { Item } from '../shared/form-control/DropdownDefaultComponents';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import QuickLinkColourPicker from './QuickLinkColourPicker';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TranslatableInput from '../shared/form-control/TranslatableInput';
import { useScrollToAndFocus } from '../../hooks/useScrollToAndFocus';

type FavouriteFormWithId = FavouriteForm & {
  id: string;
};

type ManageProps = {
  quickLinks: FavouriteFormWithId[];
  templateForms: GroupBase<Item>[];
  focusLast?: boolean;
  onDragEnd: (newValue: FavouriteFormWithId[]) => void;
  onItemChange: (newValue: FavouriteFormWithId) => void;
};

type ManageHandle = {
  validate: () => boolean;
};

export const QuickLinkManage = forwardRef<ManageHandle, ManageProps>(function QuickLinkManage(props, ref) {
  const { onDragEnd, onItemChange, quickLinks, templateForms, focusLast } = props;
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const validationRefs = useRef<(ComponentRef<typeof TranslatableInput> | null)[]>([]);
  const [selfValid, setSelfValid] = useState(true);

  const validateSelf = useCallback(() => {
    let valid = true;
    // `.every` returns early, we want to loop through everything
    for (const input of validationRefs.current.filter(Boolean)) {
      if (!input.validate()) {
        valid = false;
      }
    }

    setSelfValid(valid);
    return valid;
  }, []);

  const getIndex = useCallback((id: UniqueIdentifier) => quickLinks.findIndex((x) => x.id === id), [quickLinks]);

  const onDragStartInternal = useCallback((event: DragStartEvent) => {
    if (!event.active) {
      return;
    }

    setActiveId(event.active.id);
  }, []);

  const onDragEndInternal = useCallback(
    (event: DragEndEvent) => {
      if (!selfValid) {
        validateSelf();
      }

      setActiveId(null);

      if (event.over) {
        const activeIndex = activeId ? getIndex(activeId) : -1;
        const overIndex = getIndex(event.over.id);
        if (activeIndex !== overIndex) {
          onDragEnd(arrayMove(quickLinks, activeIndex, overIndex));
        }
      }
    },
    [activeId, getIndex, onDragEnd, quickLinks, selfValid, validateSelf],
  );

  const deleteItem = useCallback(
    (id: string) => {
      const itemIndex = quickLinks.findIndex((x) => x.id === id);
      validationRefs.current.splice(itemIndex, 1);
      onDragEnd(quickLinks.filter((x) => x.id !== id));
    },
    [onDragEnd, quickLinks],
  );

  const onItemChangeInternal = useCallback(
    (newItem: FavouriteFormWithId) => {
      if (!selfValid) {
        validateSelf();
      }

      onItemChange(newItem);
    },
    [onItemChange, selfValid, validateSelf],
  );

  useImperativeHandle(
    ref,
    () => ({
      validate() {
        return validateSelf();
      },
    }),
    [validateSelf],
  );

  return (
    <DndContext
      onDragStart={onDragStartInternal}
      onDragEnd={onDragEndInternal}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
    >
      <SortableContext items={quickLinks}>
        {quickLinks.map((ql, i) => (
          <QuickLinkManageItem
            key={ql.id}
            quickLink={ql}
            templateForms={templateForms}
            deleteItem={deleteItem}
            onChange={onItemChangeInternal}
            focus={i + 1 === quickLinks.length ? focusLast : false}
            ref={(ref) => validationRefs?.current && (validationRefs.current[i] = ref)}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
});

type ItemProps = {
  quickLink: FavouriteFormWithId;
  templateForms: GroupBase<Item>[];
  focus?: boolean;
  onChange: (newValue: FavouriteFormWithId) => void;
  deleteItem: (id: string) => void;
};

type ItemHandle = {
  validate: () => boolean;
};

export const QuickLinkManageItem = forwardRef<ItemHandle, ItemProps>(function QuickLinkManageItem(props, ref) {
  const { quickLink, templateForms, onChange, deleteItem, focus } = props;
  const outerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const translatableInputRef = useRef<ComponentRef<typeof TranslatableInput>>(null);
  const [templateInvalidState, setTemplateInvalidState] = useState(false);

  const { t } = useTranslation('home-page');
  const { attributes, listeners, transform, transition, setNodeRef, setActivatorNodeRef, isDragging } = useSortable({ id: quickLink.id as string });

  const currentTemplate = useMemo(() => {
    return templateForms.flatMap((x) => x.options).find((x) => x.id === quickLink.templateFormId);
  }, [quickLink.templateFormId, templateForms]);

  useImperativeHandle(
    ref,
    () => ({
      validate() {
        const isTemplateValid = !!currentTemplate;
        setTemplateInvalidState(!isTemplateValid);

        const inputResult = translatableInputRef.current?.validate();
        return isTemplateValid && !!inputResult;
      },
    }),
    [currentTemplate],
  );

  const triggerScrollTo = useScrollToAndFocus(inputRef.current, outerRef.current);

  useEffect(() => {
    if (focus) {
      triggerScrollTo();
    }
  }, [focus, triggerScrollTo]);

  const deleteItemInternal = useCallback(() => {
    deleteItem(quickLink.id);
  }, [deleteItem, quickLink.id]);

  const onChangeInternal = useCallback(
    <T extends keyof typeof quickLink>(key: T, value: (typeof quickLink)[T]) => {
      onChange({ ...quickLink, [key]: value });
    },
    [onChange, quickLink],
  );

  return (
    <div
      ref={(ref) => {
        setNodeRef(ref);
        outerRef.current = ref;
      }}
      style={{ transform: CSS.Transform.toString(transform), transition }}
      className={`bg-background-1 group relative my-4 flex items-center gap-2 rounded-md px-2 py-4 ${isDragging ? 'z-50' : ''}`}
      data-cy="quick-link-manage-item"
    >
      <div
        ref={setActivatorNodeRef}
        {...listeners}
        {...attributes}
        className={`hover:bg-gray-4 flex-shrink-0 rounded-md p-2 opacity-0 group-hover:opacity-100 ${
          isDragging ? 'bg-gray-4 cursor-grabbing' : 'cursor-grab'
        }`}
      >
        <DragHandleIcon className="text-gray-5 h-6 w-4" />
      </div>
      <QuickLinkColourPicker value={quickLink.color} onChange={(v) => onChangeInternal('color', v)} />
      <div className="flex flex-grow flex-col gap-4">
        <TranslatableInput
          ref={translatableInputRef}
          innerRef={inputRef}
          placeholder={t('quick-links.manage.title-placeholder')}
          aria-label={t('quick-links.manage.title-placeholder')}
          translations={quickLink.translations}
          translationKey="title"
          onTranslationsChange={(value) => onChangeInternal('translations', value)}
          data-cy="quick-link-manage-title"
        />
        <DropdownSelect
          errorState={templateInvalidState}
          options={templateForms}
          onChange={(o) => onChangeInternal('templateFormId', o.id)}
          value={currentTemplate}
          placeholder={t('quick-links.manage.template-placeholder')}
          data-cy="quick-link-template-select"
          aria-label={t('quick-links.manage.template-placeholder')}
        />
      </div>
      <div className="flex-shrink-0">
        <TrashIcon data-cy="delete-quick-link-button" className="h-8 w-8 opacity-0 group-hover:opacity-100" onClick={deleteItemInternal} />
      </div>
    </div>
  );
});
